import fetch from 'cross-fetch';
// import { _showAlert } from './index';

export const _checkValidAuthenticate = async () => {
  // time + 1 min
  const expirationTime = sessionStorage.getItem('expirationtime') || 0 + 1 * 60000;
  const authData = sessionStorage.getItem('authData');
  var newDate = new Date().getTime()

  if (authData && expirationTime <= newDate) await _refreshToken();
};

export const _isAuthenticated = () => {
  _checkValidAuthenticate();
  const authData = sessionStorage.getItem('authData');
  const parsedData = authData ? JSON.parse(authData) : {};
  const { tokens } = parsedData || {};
  return {
    tokens,
  };
};

export const _refreshToken = () => {
  let APP_BASE_URL = window.location.origin
  let authData = sessionStorage.getItem('authData');
  let parcedAuthData = authData ? JSON.parse(authData) : {};
  return fetch(`${process.env.REACT_APP_API_URL}/refreshToken`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ refreshToken: parcedAuthData.tokens.refreshToken }),
  })
    .then((response) => response)
    .then((parcedResponse) => {
      if (parcedResponse.status === 200) {
        let successRes = parcedResponse.json();
        successRes.then((data) => {
          // _showAlert('Access token has been updated successfully please do the request again or refresh the page', 'success', 10000);
          let newAuthData = {
            ...parcedAuthData,
            tokens: {
              ...parcedAuthData.tokens,
              accessToken: data.token,
            },
          };
          sessionStorage.setItem('authData', JSON.stringify(newAuthData));
          const expirationtime = new Date().getTime() + 9 * 60000;
          sessionStorage.setItem('expirationtime', expirationtime);
          // _showAlert('A Access token has been updated!!!!!!', 'success', 10000);
        });
      }
      if (
        parcedResponse.status === 403 ||
        parcedResponse.status === 401 ||
        parcedResponse.status === 504 ||
        parcedResponse.status === 400 ||
        parcedResponse.status === 500
      ) {
        sessionStorage.removeItem('authData');
        sessionStorage.removeItem('expirationtime');
        window.location.href = `${APP_BASE_URL}?authFailed=${true}`;
      }
    })
    .catch((error) => {
      sessionStorage.removeItem('authData');
      sessionStorage.removeItem('expirationtime');
      window.location.href = `${APP_BASE_URL}?authFailed=${true}`;
    });
};
