/**
 * Name: add new songs saga
 * Author: @Gaurav
 * Date: 19-07-2021
 */
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiCaller from '../ApiCaller';
import { _isAuthenticated } from '../../utils';

const callAPI = (payload, params) => {
  // const expirationTime = sessionStorage.getItem('expirationtime');
  // var newDate = new Date()

  // if (expirationTime <= newDate.getTime()){
  //   _refreshToken();
  // }

  const { tokens } = _isAuthenticated();
  const headers = {
    authorization: `${tokens?.accessToken}`,
  };
  console.log(headers, payload);
  return ApiCaller(`/v1/admin/songs/${params}`, 'POST', payload, headers);
};

export default function* watchRequest() {
  yield takeLatest('ADD_NEW_SONGS', function* performAction(action) {
    yield put({ type: 'ADD_NEW_SONGS_INITIATED' });
    try {
      const data = yield call(callAPI.bind(this, action.payload, action.params));
      yield put({ type: 'ADD_NEW_SONGS_SUCCESS', payload: data });
    } catch (error) {
      yield put({ type: 'ADD_NEW_SONGS_FAILED', payload: error });
    }
  });
}

