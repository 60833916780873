import React, { useEffect, useRef, useState } from 'react';
import { Slider } from '@material-ui/core';
import { _showAlert } from '../../utils';
import { formatTime } from '../../utils/DateUtils';
import { MEDIA_URL } from '../../utils/consts';

const SongPlayer = ({ songDetail, onClose }) => {
  const audioRef = useRef(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [isSongDownloading, setIsSongDownloading] = useState(false);

  const handleSeek = (event, newValue) => {
    audioRef.current.currentTime = newValue;
    setCurrentTime(newValue);
  };

  const handleTimeUpdate = (event) => {
    setCurrentTime(event.target.currentTime);
    setDuration(event.target.duration);
  };

  const handleLoadedMetadata = (event) => {
    setCurrentTime(event.target.currentTime);
    setDuration(event.target.duration);
  };

  const handlePlayPause = (e) => {
    let playPromise = audioRef.current.play();

    if (isPlaying) {
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            audioRef.current.pause();
          })
          .catch((error) => { });
      }
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    audioRef.current.muted = !audioRef.current.muted;
    if (isMute) {
      setIsMute(false);
    } else {
      setIsMute(true);
    }
  };

  const handleAudioDownload = async () => {
    if (isSongDownloading) return;

    _showAlert('Downloading started', 'success');
    setIsSongDownloading(true);
    const res = await fetch(`${process.env.REACT_APP_API_URL}songs/${songDetail.id}.mp3`);
    const blob = await res.blob();
    const file = new File([blob], `${songDetail?.name || 'song'}.mp3`, { type: 'audio/mpeg' });
    const url = window.URL.createObjectURL(file);

    const a = document.createElement('a');
    a.class = 'download-link';
    a.href = url;
    a.download = `${songDetail?.name || 'song'}.mp3`;
    a.target = '_blank';
    a.click();
    a.remove();

    setIsSongDownloading(false);
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (songDetail.id) {
      audio.play();
      setIsPlaying(true);
    }

    return () => {
      audio.pause();
      setIsPlaying(false);
    };
  }, [songDetail]);

  return (
    <>
      <audio
        style={{ display: 'none' }}
        id="myAudio"
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        crossOrigin="anonymous"
        preload="auto"
      >
        <source src={songDetail.id ? `${MEDIA_URL}songs/${songDetail.id}.mp3` : ''} type="audio/mpeg" />
      </audio>
      <div className="play-song-tooltip">
        <div className="tooltip-title">
          <h5
            // className="title"
            style={{
              textTransform: 'capitalize',
              width: '200px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {songDetail?.name || 'Song Name'}
          </h5>

          <div style={{ display: 'flex', gap: '15px' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ cursor: 'pointer' }}
              onClick={handleAudioDownload}
            >
              <path
                d="M3.25 18.1927C2.65326 18.1927 2.08097 17.9556 1.65901 17.5337C1.23705 17.1117 1 16.5394 1 15.9427V12.7284C1 12.4726 1.10159 12.2274 1.28243 12.0465C1.46327 11.8657 1.70854 11.7641 1.96429 11.7641C2.22003 11.7641 2.4653 11.8657 2.64614 12.0465C2.82698 12.2274 2.92857 12.4726 2.92857 12.7284V15.9427C2.92857 16.1201 3.07257 16.2641 3.25 16.2641H16.75C16.8352 16.2641 16.917 16.2302 16.9773 16.1699C17.0376 16.1097 17.0714 16.0279 17.0714 15.9427V12.7284C17.0714 12.4726 17.173 12.2274 17.3539 12.0465C17.5347 11.8657 17.78 11.7641 18.0357 11.7641C18.2915 11.7641 18.5367 11.8657 18.7176 12.0465C18.8984 12.2274 19 12.4726 19 12.7284V15.9427C19 16.5394 18.7629 17.1117 18.341 17.5337C17.919 17.9556 17.3467 18.1927 16.75 18.1927H3.25Z"
                fill="black"
              />
              <path
                d="M9.03571 10.0785V2.76409C9.03571 2.50835 9.13731 2.26308 9.31815 2.08224C9.49899 1.9014 9.74426 1.7998 10 1.7998C10.2557 1.7998 10.501 1.9014 10.6819 2.08224C10.8627 2.26308 10.9643 2.50835 10.9643 2.76409V10.0785L13.4971 7.54695C13.5866 7.45746 13.6929 7.38648 13.8098 7.33805C13.9267 7.28962 14.052 7.26469 14.1786 7.26469C14.3051 7.26469 14.4304 7.28962 14.5474 7.33805C14.6643 7.38648 14.7705 7.45746 14.86 7.54695C14.9495 7.63643 15.0205 7.74267 15.0689 7.85959C15.1173 7.97651 15.1423 8.10182 15.1423 8.22838C15.1423 8.35493 15.1173 8.48024 15.0689 8.59716C15.0205 8.71408 14.9495 8.82032 14.86 8.9098L10.6814 13.0884C10.592 13.1779 10.4858 13.249 10.3688 13.2975C10.2519 13.346 10.1266 13.3709 10 13.3709C9.87342 13.3709 9.74809 13.346 9.63116 13.2975C9.51423 13.249 9.40801 13.1779 9.31857 13.0884L5.14 8.9098C5.05051 8.82032 4.97953 8.71408 4.9311 8.59716C4.88267 8.48024 4.85774 8.35493 4.85774 8.22838C4.85774 8.10182 4.88267 7.97651 4.9311 7.85959C4.97953 7.74267 5.05051 7.63643 5.14 7.54695C5.22949 7.45746 5.33572 7.38648 5.45264 7.33805C5.56956 7.28962 5.69488 7.26469 5.82143 7.26469C5.94798 7.26469 6.0733 7.28962 6.19021 7.33805C6.30713 7.38648 6.41337 7.45746 6.50286 7.54695L9.03571 10.0785Z"
                fill="black"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ cursor: 'pointer' }}
              onClick={onClose}
            >
              <path
                d="M15.2999 16.4833L9.99994 11.1749L4.69993 16.4833L3.5166 15.2999L8.82493 9.99994L3.5166 4.69993L4.69993 3.5166L9.99994 8.82494L15.2999 3.52494L16.4749 4.69993L11.1749 9.99994L16.4749 15.2999L15.2999 16.4833Z"
                fill="black"
              />
            </svg>
          </div>
        </div>

        <Slider size="small" value={currentTime} onChange={handleSeek} max={duration} aria-label="Small" />

        <div className="tooltip-footer">
          <div>
            <span style={{ width: 40, height: 40, display: 'inline-block', cursor: 'pointer' }} onClick={handlePlayPause}>
              {isPlaying ? (
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M36 20C36 28.8366 28.8366 36 20 36C11.1634 36 4 28.8366 4 20C4 11.1634 11.1634 4 20 4C28.8366 4 36 11.1634 36 20Z"
                    fill="#6B3EB4"
                  />
                  <path
                    d="M16 16C16 15.4477 16.4477 15 17 15H18C18.5523 15 19 15.4477 19 16V24C19 24.5523 18.5523 25 18 25H17C16.4477 25 16 24.5523 16 24V16Z"
                    fill="white"
                  />
                  <path
                    d="M22 16C22 15.4477 22.4477 15 23 15H24C24.5523 15 25 15.4477 25 16V24C25 24.5523 24.5523 25 24 25H23C22.4477 25 22 24.5523 22 24V16Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                  <path
                    d="M20.1761 3.3335C16.845 3.3335 13.5888 4.32127 10.8191 6.1719C8.04945 8.02254 5.89076 10.6529 4.61602 13.7304C3.34128 16.8079 3.00775 20.1943 3.65761 23.4613C4.30746 26.7284 5.91152 29.7294 8.26693 32.0848C10.6223 34.4402 13.6233 36.0442 16.8904 36.6941C20.1574 37.3439 23.5438 37.0104 26.6213 35.7357C29.6988 34.4609 32.3292 32.3022 34.1798 29.5326C36.0304 26.7629 37.0182 23.5067 37.0182 20.1756C37.0182 17.9639 36.5826 15.7738 35.7362 13.7304C34.8898 11.687 33.6492 9.83037 32.0853 8.26643C30.5213 6.7025 28.6647 5.46192 26.6213 4.61553C24.5779 3.76913 22.3878 3.3335 20.1761 3.3335ZM16.8904 25.7236V13.7407L26.1204 20.1756L16.8904 25.7236Z"
                    fill="#40189D"
                  />
                </svg>
              )}
            </span>

            <span onClick={toggleMute}>
              {isMute ? (
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule={'evenodd'}
                    d="M18.9145 12.0802C19.196 12.2155 19.375 12.5002 19.375 12.8125V27.9792C19.375 28.2915 19.196 28.5762 18.9145 28.7115C18.633 28.8468 18.2988 28.8087 18.0549 28.6136L12.8608 24.4583H8.8125C8.36377 24.4583 8 24.0946 8 23.6458V17.1458C8 16.6971 8.36377 16.3333 8.8125 16.3333H12.8608L18.0549 12.1781C18.2988 11.9829 18.633 11.9449 18.9145 12.0802ZM17.75 14.503L13.6534 17.7803C13.5093 17.8956 13.3303 17.9583 13.1458 17.9583H9.625V22.8333H13.1458C13.3303 22.8333 13.5093 22.8961 13.6534 23.0114L17.75 26.2887V14.503ZM24.488 16.5713C24.8053 16.254 25.3197 16.254 25.637 16.5713L28.3125 19.2468L30.988 16.5713C31.3053 16.254 31.8197 16.254 32.137 16.5713C32.4543 16.8886 32.4543 17.4031 32.137 17.7204L29.4615 20.3958L32.137 23.0713C32.4543 23.3886 32.4543 23.9031 32.137 24.2204C31.8197 24.5377 31.3053 24.5377 30.988 24.2204L28.3125 21.5449L25.637 24.2204C25.3197 24.5377 24.8053 24.5377 24.488 24.2204C24.1707 23.9031 24.1707 23.3886 24.488 23.0713L27.1635 20.3958L24.488 17.7204C24.1707 17.4031 24.1707 16.8886 24.488 16.5713Z"
                    fill="#121212"
                  />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.0989 11.9122C27.4169 11.5845 27.9323 11.5846 28.2502 11.9124C30.4375 14.1681 31.6663 17.227 31.6663 20.4165C31.6663 23.606 30.4375 26.665 28.2502 28.9206C27.9323 29.2484 27.4169 29.2485 27.0989 28.9208C26.781 28.5931 26.7809 28.0618 27.0988 27.734C28.9809 25.7931 30.0383 23.161 30.0383 20.4165C30.0383 17.672 28.9809 15.0399 27.0988 13.099C26.7809 12.7712 26.781 12.2399 27.0989 11.9122ZM19.2681 11.8277C19.5501 11.9674 19.7295 12.2614 19.7295 12.584V28.249C19.7295 28.5716 19.5501 28.8656 19.2681 29.0054C18.9861 29.1451 18.6513 29.1058 18.4069 28.9043L13.203 24.6125H9.14704C8.69746 24.6125 8.33301 24.2368 8.33301 23.7733V17.0597C8.33301 16.5963 8.69746 16.2205 9.14704 16.2205H13.203L18.4069 11.9287C18.6513 11.7272 18.9861 11.6879 19.2681 11.8277ZM18.1014 14.3301L13.9971 17.715C13.8527 17.8341 13.6734 17.8989 13.4886 17.8989H9.96108V22.9341H13.4886C13.6734 22.9341 13.8527 22.9989 13.9971 23.118L18.1014 26.5029V14.3301ZM23.2676 15.862C23.5855 15.5343 24.1009 15.5344 24.4188 15.8622C25.5888 17.0687 26.246 18.7049 26.246 20.4109C26.246 22.1169 25.5888 23.7531 24.4188 24.9596C24.1009 25.2874 23.5855 25.2875 23.2676 24.9598C22.9496 24.6321 22.9495 24.1008 23.2674 23.773C24.1322 22.8812 24.618 21.6719 24.618 20.4109C24.618 19.1499 24.1322 17.9406 23.2674 17.0488C22.9495 16.721 22.9496 16.1897 23.2676 15.862Z"
                    fill="#121212"
                  />
                </svg>
              )}
            </span>
          </div>

          <span className="timer">
            {formatTime(currentTime)} / {formatTime(duration)}
          </span>
        </div>
      </div>
    </>
  );
};

export default SongPlayer;

