/**
 * Name: AddSongs
 * Author: @Gaurav
 * Date: 20-07-2021
 */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/SideBar';
import NavBar from '../../components/NavBarSearchSong';
import DftButton from '../../components/Button';
import Settings from '../../assets/Settings.png';
import '../AddSong/Addsong.scss';
import SelectedSongsListSection from './SelectedSongsListSection';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import ProgressBar from '../../components/ProgressBar';
import { useHistory } from 'react-router-dom';
import { _showAlert } from '../../utils';
import { _checkValidAuthenticate } from '../../utils/AuthUtils';

function AddSongs() {
  const dispatch = useDispatch();
  const addNewSongsData = useSelector((state) => state.addNewSongs.result);
  const [newSelectedSongsList, setNewSelectedSongs] = useState([]);
  const [count, setCount] = useState(0);
  const [indexToSend, setIndexToSend] = useState(0);
  const [date, setDate] = useState('');
  const [isUploadingSongs, setUploadingSongs] = useState(false);
  const history = useHistory();
  /**
   * Add Songs functionality
   */
  const handleUpload = () => {
    document.getElementById('song-picker').click();
  };

  const handleFileSelect = (event) => {
    setNewSelectedSongs(event.target.files);
  };

  const handleUploadClick = async (index) => {
    if (newSelectedSongsList[index]) {
      setIndexToSend(index);
      setUploadingSongs(true);
      let formData = new FormData();
      formData.append('file', Object.values(newSelectedSongsList)[index], Object.values(newSelectedSongsList)[index].name);
      console.log('Date===>', date, formData);

      await _checkValidAuthenticate(); //* check before calling the API
      let songData = formData;
      dispatch({ type: 'ADD_NEW_SONGS', payload: songData, params: date });
    } else {
      dispatch({ type: 'CLEAR_STATE', actionName: 'ADD_NEW_SONGS' });
      history.push('/search-songs');
    }
  };

  useEffect(() => {
    if (addNewSongsData.status === 200) {
      setCount(count + 1);
      let newIndexToSend = indexToSend + 1;
      _showAlert(`${Object.values(newSelectedSongsList)[indexToSend].name} added successfully`, 'success');
      setTimeout(() => handleUploadClick(newIndexToSend), 1000);
    }
    if (addNewSongsData?.error) {
      _showAlert(addNewSongsData?.error, 'error');
      setCount(0);
      setUploadingSongs(false);
    }
    if (date && count <= 0) {
      setCount(count + 1);
      handleUploadClick(0);
    }
  }, [addNewSongsData, date]);

  const renderView = () => {
    if (isUploadingSongs) {
      return (
        <Box width="100%" height="60vh" display="flex" justifyContent="center" alignItems="center">
          <Box width="40%" textAlign="center">
            <Typography className="uploading-text">
              Uploading[{count - 1}/{newSelectedSongsList.length}]...
            </Typography>
            <ProgressBar />
          </Box>
        </Box>
      );
    }
    if (newSelectedSongsList?.length > 0) {
      return <SelectedSongsListSection songs={newSelectedSongsList} onUploadClick={() => setDate(parseInt(new Date().getTime() / 1000))} />;
    }
    return (
      <>
        <DftButton onClick={handleUpload} button="Choose Songs" />
        <input
          type="file"
          id="song-picker"
          style={{ display: 'none' }}
          onChange={handleFileSelect}
          multiple={true}
          accept="audio/mp3,audio/*;capture=microphone"
        />
      </>
    );
  };
  return (
    <div className="main empty_page">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="leftPortion">
        <NavBar data="Add song" icon={Settings} currentRouteName="Songs" breadcrumbsPath="Add Songs / Songs List" />
        <div className="content empty_row">{renderView()}</div>
      </div>
    </div>
  );
}
export default AddSongs;

